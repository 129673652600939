/* ServiceCard.css */
.service-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-image {
    width: 100%;
    height: 200px; /* Set a fixed height for the images */
    object-fit: cover; /* Ensure the images maintain aspect ratio */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .service-content {
    padding: 20px;
  }
  
  .service-title {
    color: #3A4473;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-description {
    color: #666;
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .read-more-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3A4473;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .read-more-button:hover {
    background-color: #2A2F4A;
  }