.cctvInstallation {
  width: 100%;
  margin-top: 100px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  height: 70vh;
  margin-bottom: 20px;
}

.cctvInstallationCard {
  display: flex;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.cctvImageContainer {
  width: 50%;
  position: relative;
}

.cctvInstallationImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  position: relative;
  z-index: 0;
}

.cctvInstallationContent {
  width: 60%;
  padding: 30px;
  text-align: justify;
  max-height: 650px; /* Adjust height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  background: white;
  z-index: 1; /* Ensure content is above image content */
}

.cctvInstallationContent h2 {
  margin-top: 0;
  color: #3A4473;
  font-size: 1.4rem;
  text-align: center;
}

.cctvInstallationContent p {
  font-size: 1rem;
}

.check-icon {
  vertical-align: middle;
  color: #af0c0b;
}

.cctvInstallationContent ul {
  list-style-type: none;
  padding: 0;
}

.bookSurveyButton {
  background-color: #af0c0b;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}
.bookSurveyButton a{
  text-decoration: none;
  color: #ffffff;
}


.bookSurveyButton:hover {
  background-color: #3A4473;
}

@media only screen and (max-width: 768px) {
  .cctvInstallation {
    height: auto;
    margin-bottom: 10px;
  }
    .cctvInstallationCard {
      flex-direction: column;
      padding: 0px;
    }
  
    .cctvImageContainer {
      width: 100%;
      height: 250px;
    }
  
    .cctvInstallationImage {
      clip-path: none;
      height: 250px;
    }
  
    .cctvInstallationContent {
      width: 90%;
      padding: 15px;
      margin-left: 0px;
      max-height: 100%;
      height: 100%; /* Remove max-height for smaller screens */
      overflow-y: auto;
    }
  
    .cctvInstallationContent h2 {
      font-size: 1.2rem;
      margin-top: 10px;
    }
  
    .cctvInstallationContent p {
      font-size: 0.9rem;
    }
  }
