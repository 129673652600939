/* CanineServices.css */

.canineServicesSection {
    max-width: 100%;
    margin-top: 120px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: center;
  }
  
  .canineServicesTitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: #af0c0b;
    text-align: center;
  }
  
  .canineServicesDescription {
    font-size: 16px;
    text-align: justify;
  }
  
  .canineService {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-color: #ccc;
    max-width: 100%;
  }
  
  .serviceHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .serviceHeader:hover {
    background-color: #f0f0f0; /* Change to your desired hover background color */
  }
  
  .serviceTitle {
    font-size: 20px;
    margin: 0;
    color: #3A4473;
  }
  
  .expandButton {
    font-size: 18px;
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .expandButton:hover {
    background-color: #ccc; /* Change to your desired hover background color */
  }
  
  .serviceDetails {
    margin-top: 0px;
    padding-left: 5px;
    display: none; /* Initially hide service details */
  }
  
  .serviceDetails.active {
    display: flex; /* Use flexbox to align items */
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
    background-color: #fff;
  }
  
  .serviceDetails.active ul {
    list-style-type: none;
  }
  
  .serviceImage {
    width: 45%; /* Cover 50% of the width */
    height: 300px;
    border-radius: 10px 0 0 10px; /* Add border radius to the left side */
  }
  
  .serviceDetails p {
    width: 50%; /* Cover 50% of the width */
    text-align: justify;
    padding: 10px; /* Add padding to separate from the image */
  }
  
  @media screen and (max-width: 600px) {
    .canineServicesSection {
      padding: 10px;
    }
  
    .serviceTitle {
      font-size: 18px;
    }
  
    .expandButton {
      font-size: 16px;
      padding: 3px 6px;
    }
  
    .serviceDetails {
      padding-left: 10px;
    }
  
    .serviceDetails.active {
      flex-direction: column;
      padding: 10px;
    }
  
    .serviceImage {
      width: 100%; /* Cover 100% of the width */
      height: 250px;
    }
  
    .serviceDetails p {
      width: 100%; /* Cover 50% of the width */
      padding: 0;
    }
  }
  