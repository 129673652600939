.alarmMonitoringSection {
  max-width: 100%;
  margin-top: 120px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.alarmMonitoringTitle {
  font-size: 24px;
  margin-bottom: 20px;
  color: #3A4473;
  text-align: center;
}

.alarm-highlighted-text {
  color: #af0c0b;
}

.alarmMonitoringDescription {
  font-size: 1.4rem;
  text-align: justify;
  margin-left: 20%;
  width: 60%;
}

.alarmService {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.serviceHeader:hover {
  background-color: #f0f0f0; /* Change to your desired hover background color */
}

.serviceTitle {
  font-size: 1.4rem;
  margin: 0;
  color: #3A4473;
}

.alarm-highlighted-Servicetext {
  color: #af0c0b;
}

.expandButton {
  font-size: 18px;
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.expandButton:hover {
  background-color: #ccc; /* Change to your desired hover background color */
}

.serviceDetails {
  margin-top: 0px;
  padding-left: 5px;
  display: none; /* Initially hide service details */
}

.serviceDetails.active {
  display: flex; /* Use flexbox to align items */
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
  background-color: #fff;
  max-width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
}

.serviceDetails.active ul {
  list-style-type: none;
}

.serviceImage {
  width: 40%; /* Cover 50% of the width */
  height: 100%;
  border-radius: 10px 0 0 10px; /* Add border radius to the left side */
}

.serviceDetails p {
  width: 55%; /* Cover 50% of the width */
  text-align: justify;
  padding: 10px; /* Add padding to separate from the image */
}

@media screen and (max-width: 600px) {
  .alarmMonitoringSection {
    padding: 10px;
  }

  .alarmMonitoringTitle {
    font-size: 1.2rem;
  }

  .alarmMonitoringDescription {
    font-size: 1rem;
    width: 80%;
  }

  .serviceHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .serviceTitle {
    font-size: 1rem;
  }

  .serviceDetails.active {
    flex-direction: column;
    align-items: flex-start;
  }

  .serviceImage,
  .serviceDetails p {
    width: 100%;
  }
}
