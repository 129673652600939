/* Footer.css */
.footer {
  background-color: #af0c0b; /* Parkville Medical Center color */
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  border-top: 2px solid #ffffff; /* White border at the top */
}

.footer h4 {
  margin-bottom: 10px;
  color: #ffffff; /* Dark color for headings */
  font-size: 24px;
}
.footer-highlighted-text1 {
  color: #3A4473;
  font-size: 1.4rem;
}
.footer p{
  color: #ffffff;
  font-weight: 100;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer li {
  margin-bottom: 5px;
  color: #ffff; /* Dark color for links */
  font-weight: 200;
}

.footer a {
  text-decoration: none;

}

.footer-sectionLinks li:hover {
  text-decoration: underline;
  color: #3A4473;
}

.footer-section li {
  color: #fff; /* Dark color for list items */
  text-decoration: none;
  font-weight: 400;
}

.submenu-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Style for submenu text and links */
.sub-menu {
  list-style: none;
  margin-top: 10px;
}

.sub-menu li {
  color: #1a1a1a; /* Dark color for submenu items */
  display: flex;
  align-items: center;
}

.sub-menu a {
  text-decoration: none;
  color: #000000; /* Red color for submenu links */
  padding-left: 20px;
}

.sub-menu a:hover {
  text-decoration: underline;
  color: #3A4473; /* Red color for submenu links on hover */
}

.rotate-icon {
  transform: rotate(45deg);
}

.footer-section {
  width: 30%;
}

/* Style for social media icons */
.social-icons1 {
  display: flex;
  align-items: center;
}

.social-icons1 a {
  color: #f9f9f9; /* Dark color for social media icons */
  text-decoration: none;
  margin-right: 10px;
  font-size: 24px;
}

.social-icons1 a:hover {
  text-decoration: none;
}

.plus-icon,
.minus-icon {
  color: #f9f9f9; /* Dark color for plus and minus icons */
  font-size: 12px;
  padding-left: 5px;
}

/* Bottom line for copyright notice */
.bottom-line {
  color: #1a1a1a; /* Dark color for the bottom line */
  text-align: center;
  margin-top: auto; /* Automatically takes up the available space */
  padding-top: 10px;
  border-top: 2px solid #ffffff; /* White border at the top */
  margin-top: 50px;

}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 20px;
    padding-left: 50px;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer ul {
    text-align: left;
  }
  .social-icons1 {
    padding: 10px;
  }
}
