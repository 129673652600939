body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Ensure any parent containers of .hero-section also have 100% width */
.parent-container {
  width: 100%;
}

/* Remove any default padding/margin */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust height as needed */
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #E6E8E9;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%; /* Adjust to cover 40% of the hero section width */
  height: 100%;
  background-color: #E6E8E9;
  z-index: 1; /* Ensure it's above the slider */
  clip-path: polygon(0 0, 50.5% 0, 100% 100%, 0 100%);
}

/* Customize arrows */
.slick-prev,
.slick-next {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8) !important; /* Customize arrow color */
  background-color: rgb(232,44,44); /* Arrow background color */
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 3;
  position: absolute;
  top: 90vh;
  transform: translateY(-50%);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.slick-prev {
  left: 70px; /* Adjust position */
}

.slick-next {
  left: 120px; /* Adjust position */
}

.slick-prev:hover,
.slick-next:hover {
  color: #af0c0b; /* Hover text color */
}

.slider-image {
  position: relative;
  width: 100%;
  height: 140vh;
  overflow: hidden;
}

.slider-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-left: 20%;
  transition: transform 1s ease-in-out;
}

.hero-text {
  position: absolute;
  top: 25%;
  max-width: 50%;
  left: 30px;
  transform: translateY(100%);
  text-align: left;
  opacity: 0;
  animation: slideIn 1s ease-in-out forwards;
  z-index: 2;
  line-height: 1;
}

/* Animation keyframes */
@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.hero-text h1 {
  color: #333333;
  font-size: 42px;
  line-height: 1;
}

.green-text {
  color: #333333;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1;
}

.hero-button2 {
  display: inline-block;
  padding: 12px 24px;
  background-color: #af0c0b;
  color: #fff;
  text-decoration: none;
  margin-top: 2px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  overflow: hidden;
  clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
  font-size: 16px;
}

.hero-button2:hover {
  background-color: #3a4473;
  transform: scale(1.05);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .hero-text {
    font-size: 18px;
    margin-top: 25px;
  }

  .hero-section {
    height: 80vh;
  }

  .hero-text h1 {
    font-size: 36px;
  }

  .green-text {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 90vh;
  }

  .slider-image {
    height: 90vh;
  }

  .hero-text {
    font-size: 1.2rem;
    max-width: 60%;
    left: 15px;
    margin-top: 10%;
  }
  .hero-text h1 {
    color:#ffffff;;
    font-size: 32px;
    line-height: 1;
  }
  
  .green-text {
    color: #ffffff;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1;
  }

  .green-text {
    font-size: 0.9rem;
  }

  .hero-button2 {
    padding: 10px 16px;
    font-size: 14px;
  }
  .slick-prev,
.slick-next {
  font-size: 18px;
  top: 80vh;
}
.overlay {
  width: 100%;
  clip-path: inset(0);
  /* background-color: rgba(230, 232, 233, 0); */
  background-color: rgba(0, 0, 0, 0.2);
}
.slider-image img {
  margin-left: 0;
}
  
}
