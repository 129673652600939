/* Base styles */
.service-request-container {
    padding: 40px;
    margin-top: 100px;
    max-width: 800px; /* Adjusted to allow more space for two columns */
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto; /* Center the container */
}

.service-request-header h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #3A4473;
}

.service-request-form .form-row {
    display: flex;
    justify-content: space-between;
}

.service-request-form .form-group {
    margin-bottom: 15px;
    flex: 1;
    margin-right: 10px;
}

.service-request-form .form-group:last-child {
    margin-right: 0;
}

.service-request-form label {
    display: block;
    margin-bottom: 5px;
}

.service-request-form input,
.service-request-form select,
.service-request-form textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.service-request-form .submit-button {
    display: block;
    padding: 10px 20px;
    background-color: #3A4473;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.service-request-form .submit-button:hover {
    background-color: #af0c0b;
}

.service-request-form .button-container {
    text-align: center;
    margin-top: 20px;
}

.success-message {
    margin-top: 20px;
    text-align: center;
    color: green;
}

/* Responsive styles */
@media (max-width: 768px) {
    .service-request-container {
        padding: 15px;
    }

    .service-request-form .form-row {
        flex-direction: column;
    }

    .service-request-form .form-group {
        margin-right: 0;
    }

    .service-request-form input,
    .service-request-form select,
    .service-request-form textarea {
        padding: 7px;
    }

    .service-request-form .submit-button {
        padding: 9px;
        font-size: 15px;
    }
}

@media (max-width: 480px) {
    .service-request-container {
        padding: 10px;
    }

    .service-request-form input,
    .service-request-form select,
    .service-request-form textarea {
        padding: 6px;
    }

    .service-request-form .submit-button {
        padding: 8px;
        font-size: 14px;
    }
}
