/* BollardsTurnstilesBarriers.css */

.bollardsTurnstilesBarriersSection {
    max-width: 100%;
    margin-top: 120px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: center;
  }
  
  .bollardsTurnstilesBarriersTitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: #af0c0b;
    text-align: center;
  }
  
  .bollardsTurnstilesBarriersDescription {
    font-size: 16px;
    text-align: justify;
  }
  
  .bollardsService {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
    max-width: 100%;
  }
  
  .serviceHeaderbollards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .serviceHeaderbollards:hover {
    background-color: #f0f0f0;
  }
  
  .serviceTitlebollards {
    font-size: 20px;
    margin: 0;
    color: #3A4473;
  }
  
  .expandButtonbollards {
    font-size: 18px;
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .expandButtonbollards:hover {
    background-color: #ccc;
  }
  
  .serviceDetailsbollards {
    margin-top: 0px;
    padding-left: 5px;
    display: none;
  }
  
  .serviceDetailsbollards.active {
    display: flex;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .serviceDetailsbollards.active ul {
    list-style-type: none;
  }
  
  .serviceImagebollards {
    width: 45%;
    height: 300px;
    border-radius: 10px 0 0 10px;
  }
  
  .serviceDetailsbollards p {
    width: 50%;
    text-align: justify;
    padding: 10px;
  }
  
  .check-icon {
    vertical-align: middle;
    color: #af0c0b;
  }
  
  @media screen and (max-width: 600px) {
    .bollardsTurnstilesBarriersSection {
      padding: 10px;
    }
  
    .serviceTitlebollards {
      font-size: 18px;
    }
  
    .expandButtonbollards {
      font-size: 16px;
      padding: 3px 6px;
    }
  
    .serviceDetailsbollards {
      padding-left: 10px;
    }
  
    .serviceDetailsbollards.active {
      flex-direction: column;
      padding: 10px;
    }
  
    .serviceImagebollards {
      width: 100%;
      height: 250px;
    }
  
    .serviceDetailsbollards p {
      width: 100%;
      padding: 0;
    }
  }
  