/* ServiceSection.css */
.service-section {
    padding: 50px 0;
    text-align: center;
  }
  
  .section-title {
    color: #3A4473;
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .service-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  
  @media (min-width: 1024px) {
    .service-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1200px) {
    .service-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
