/* Sidebar.css */
.sidebar {
    position: fixed;
    top: 90px;
    right: 0;
    width: 250px;
    height: 80vh;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9999;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar.open {
    transform: translateX(0%);
  }
  
  .close-sidebar {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #3A4473;
    cursor: pointer;
  }
  
  .menu2 {
    list-style: none;
    padding: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .menu-item2 {
    margin-bottom: 10px;
  }
  
  .menu-item2 a {
    color: #3A4473;
    text-decoration: none;
    font-size: 16px;
    display: block;
    transition: color 0.3s ease-in-out; /* Add a transition for smoother color change */
  }
  
  /* Add hover styles for menu items */
  .menu-item2 a:hover {
    color:  #af0c0b;
  }
  
  .submenu2 {
    list-style: none;
    margin-top: 5px;
    padding-left: 15px;
    display: none;
  }
  
  .submenu2.open {
    display: block;
  }
  
  .submenu2-item2 {
    margin-bottom: 5px;
  } 
  
  .submenu2 a {
    color: #3A4473;
    text-decoration: none;
    padding: 5px;
    font-size: 14px;
    display: block;
    transition: color 0.3s ease-in-out; /* Add a transition for smoother color change */
  }
  
  /* Add hover styles for submenu items */
  .submenu2-item2 a:hover {
    color: #af0c0b;
  }
  
  .submenu2-toggle2 {
    background: none;
    border: none;
    padding: 0;
    font-size: 18px;
    color: #3A4473;
    cursor: pointer;
  }
  
  .submenu2-toggle2.active {
    color: #af0c0b;
    font-size: 16px;
  }
  
  
  .submenu2-toggle2:hover {
    color: #851016;
  }
  