/* Preloader.css */

.preloader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .logo {
    /* Add styles for your company logo container */
  }
  
  .logo img {
    max-width: 200px; /* Adjust the max-width of the logo */
  }
  
  .loading-bar {
    width: 15%; /* Adjust the width of the loading bar */
    height: 10px; /* Adjust the height of the loading bar */
    margin-top: 20px; /* Add space between logo and loading bar */
    background-color: #ccc; /* Color of the loading bar container */
    border-radius: 10px; /* Adjust border radius for rounded corners */
    overflow: hidden; /* Hide overflowing progress */
  }
  
  .progress {
    height: 100%; /* Ensure progress bar fills the container vertically */
    background-color: #007bff; /* Color of the loading progress */
    transition: width 0.5s ease-in-out; /* Smooth transition for progress width change */
  }
  