.contact-us-container {
  font-family: Arial, sans-serif;
  margin-top: 100px;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-us-header {
  text-align: center;
  margin-bottom: 40px;
}

.contact-us-header h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #af0c0b;
}

.contact-us-header p {
  font-size: 18px;
  color: #555;
}

.contact-us-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-us-info, .contact-us-form {
  flex: 1;
  min-width: 300px;
}

.contact-us-info h2, .contact-us-form h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #3A4473;
}

.contact-us-info ul {
  list-style: none;
  padding: 0;
}

.contact-us-info li {
  margin-bottom: 10px;
  font-size: 18px;
}

.contact-us-form .form-group {
  margin-bottom: 20px;
}

.contact-us-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #3A4473;
}

.contact-us-form input, .contact-us-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.contact-us-form .submit-button {
  padding: 15px 30px;
  background-color: #3A4473;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.contact-us-form .submit-button:hover {
  background-color: #af0c0b;
}

.contact-us-social {
  text-align: center;
  margin-top: 40px;
}

.contact-us-social h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #3A4473;
}

.social-icons a {
  margin: 0 10px;
  color: #555;
  font-size: 24px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #007BFF;
}


.success-message {
  color: green;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-us-details {
      flex-direction: column;
  }
  .contact-us-header h1 {
      font-size: 28px;
  }
  .contact-us-info h2, .contact-us-form h2 {
      font-size: 18px;
  }
  .contact-us-social h2 {
    font-size: 18px;
  }

}
