.electricFenceSolutions {
  max-width: 100%;
  max-height: 70vh;
  margin-top: 120px;
  background-color: #e9f7f6;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.electricFenceSolutionsCard {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.electricFenceImageContainer {
  width: 50%;
  position: relative;
}

.electricFenceImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  position: relative;
  z-index: 0;
}

.electricFenceContent {
  width: 60%;
  padding: 30px;
  text-align: justify;
  background: white;
  z-index: 1;
}

.electricFenceContent h2 {
  margin-top: 0;
  color: #3A4473;
  font-size: 1.4rem;
  text-align: center;
}
.highlighted-textelectric{
  color: #af0c0b;
    
}

.electricFenceContent p {
  font-size: 1rem;
}

.check-icon {
  vertical-align: middle;
  color: #af0c0b;
}

.electricFenceContent ul {
  list-style-type: none;
  padding: 0;
}

.contactButton {
  background-color: #af0c0b;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contactButton a {
  text-decoration: none;
  color: #ffffff;
  display: block;
}

.contactButton:hover {
  background-color: #2a5d67;
}

@media only screen and (max-width: 768px) {
  .electricFenceSolutions {
    width: 100%;
    padding: 0px;
    max-height: 100%;
  }
  
  .electricFenceSolutionsCard {
    flex-direction: column;
    padding: 10px;
  }

  .electricFenceImageContainer {
    width: 100%;
    height: 300px;
    clip-path: none;
  }

  .electricFenceImage {
    clip-path: none;
    height: 300px;
  }

  .electricFenceContent {
    width: 90%;
    padding: 15px;
    max-height: none;
  }

  .electricFenceContent h2 {
    font-size: 1.2rem;
    margin-top: 10px;
  }


  .electricFenceContent p {
    font-size: 0.9rem;
  }
}
