/* GuardingPost.css */

.physicalSecuritySection1 {
    max-width: 100%;
    margin-top: 100px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    padding: 20px;
  }
  .topCardSection1 {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .physicalSecurityTitle1 {
    font-size: 1.4rem;
    margin-bottom: 20px;
    color: #af0c0b;
    text-align: center;
  }
  .physical-highlighted-text1{
    color: #3A4473;
  }
  .physical-highlighted-Servicetext1{
    color: #af0c0b;
  }
  .PhysicalSecurityDescription1{
    font-size: 1.4rem;
    text-align: justify;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .physicalService1 {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
  }
  .serviceHeader1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .serviceHeader1:hover {
    background-color: #f0f0f0; /* Change to your desired hover background color */
  }
  
  .serviceTitle1 {
    font-size: 1.4rem;
    margin: 0;
    color: #3A4473;
  }
  
  .expandButton1 {
    font-size: 18px;
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .expandButton1:hover {
    background-color: #ccc; /* Change to your desired hover background color */
  }
  
  .serviceDetails1 {
    margin-top: 0px;
    padding-left: 5px;
    display: none; /* Initially hide service details */
  }

  .serviceDetails1.active {
    display: flex; /* Use flexbox to align items */
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
    background-color: #fff;
    max-width: 100%;
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
}
.serviceDetails1.active ul {
    list-style-type: none;
  
  }
  
.serviceImage1 {
    width: 40%; /* Cover 50% of the width */
    height: 100%;
    border-radius: 10px 0 0 10px; /* Add border radius to the left side */
}

.serviceDetails1 p {
    width: 55%; /* Cover 50% of the width */
    text-align: justify;
    padding: 10px; /* Add padding to separate from the image */
}

  
  @media screen and (max-width: 600px) {
    .physicalSecuritySection1 {
      padding: 0px;

    }
    .topCardSection1{
      width: 90%;

    }
    .physicalSecurityTitle1 {
      font-size: 1.2rem;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  
    .serviceTitle1 {
      font-size: 1.2rem;
    }
    .PhysicalSecurityDescription1{
      font-size: 0.9rem;
      padding: 5px;
    }
  
    .expandButton1 {
      font-size: 16px;
      padding: 3px 6px;

    }
  
    .serviceDetails1 {
      padding-left: 0px;
    }
    .serviceDetails1.active {
        flex-direction: column;
        padding: 0px;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;

      }
      .serviceImage1 {
        width: 100%; /* Cover 100% of the width */
        height: 250px;
    }
    
    .serviceDetails1 p {
        width: 100%; /* Cover 50% of the width */
        padding: 0;
    }
    .physicalService1 {
      padding: 10px;
      width: 100%;
      margin-left: 10px;
    }
    
  }
  