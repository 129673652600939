/* WhyUs.css */
.whyUsSection {
  padding: 20px;
  background-color: #ffffff;
}

.whyUsTitle {
  text-align: center;
  margin-bottom: 10px;
  color: #3A4473;
  font-size: 2rem;
}

.whyUsCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.whyUsCard {
  width: 200px;
  padding: 5px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer; /* Add cursor pointer */
}

.whyUsCard:hover {
  /* transform: scale(1.05); */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.whyUsCard h3 {
  color: #333333;
  font-size: 1.2rem;
}

.coreValueCard h3 {
  color: #fff;
  font-size: 1.2rem;
}

.coreValueCard p {
  color: #fff;
  font-size: 1rem;
}

.whyUsIcon {
  font-size: 28px;
  margin-top: 5px;
  color: #333333;
}

.coreValuesSection {
  padding: 10px;
  background-color: #ffffff;
}

.coreValuesTitle {
  text-align: center;
  margin-bottom: 10px;
  color: #3A4473;
  font-size: 2rem;
}

.coreValuesCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.coreValueCard {
  width: 200px;
  padding: 5px;
  margin: 10px;
  border-radius: 10px;
  background-color: #3A4473;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer; /* Add cursor pointer */
}

.coreValueCard:hover {
  /* transform: scale(1.05); */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.coreValueIcon {
  font-size: 28px;
  margin-top: 5px;
  color: #fff;
}


/* Media queries */
@media screen and (max-width: 768px) {
  .whyUsSection {
    padding: 10px;
  }
  .coreValuesSection {
    padding: 20px;
  }
  .whyUsCard {
    width: 100%;
    margin: 5px;
    padding: 5px;
  }
  .whyUsIcon {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .coreValueCard {
    width: 100%;
    margin: 10px;
  }
  .whyUsTitle {
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  .coreValuesTitle {
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  .whyUsCard h3 {
    font-size: 1rem;
  }
  .coreValueCard h3 {
    font-size: 1rem;
  }
  .coreValueCard p {
    font-size: 0.8rem;
  }
}
