.wwa-who-we-are-about-section {
  text-align: center;
  background-color: #ffffff;
  margin: 0;
  padding: 0px;
}

.wwa-section-about-card {
  display: flex;
  position: relative;
  width: 100%;
  margin: 0px 0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.wwa-section-about-card:hover {
  transform: translateY(-5px);
}

.wwa-section-about-title {
  color: #3A4473;
  font-size: 2rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wwa-highlighted-text {
  color: #af0c0b;
}

.wwa-content-container {
  background: #F0F0F0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  width: 60%;
  padding: 20px;
  z-index: 1;
  position: relative;
}

.wwa-content-container::before {
  content: '';
  position: absolute;
  top: 120px;
  right: 0;
  bottom: 0;
  left: 120px;
  width: 50%;
  height: 25%;
  background-image: url('../../Assets/logo/RSS logo.jpeg');
  background-size: cover;
  opacity: 0.1;
  z-index: -1;
}

.wwa-image-container {
  width: 40%;
  overflow: hidden;
  position: relative;
}

.wwa-image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.wwa-card-content {
  padding: 10px;
}

.wwa-description {
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: justify;
  width: 80%;
  margin-left: 40px;
}

.wwa-blinking-badge1 {
  background-color: #eb1b2b;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-in-out;
  margin-left: 40px;
}

.wwa-check-icon1 {
  margin-right: 5px;
}

.wwa-blinking-badge1 h3 {
  text-align: center;
  color: #3A4473;
  font-size: 1.4rem;
}

.wwa-blinking-badge1 ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: justify;
}

.wwa-blinking-badge1 li {
  margin-bottom: 5px;
  color: #fff;
  font-size: 1.2rem;
}

.wwa-read-more-about-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #af0c0b;
  color: #fff;
  margin-top: 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.wwa-read-more-about-button:hover {
  background-color: #2A2F4A;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .wwa-section-about-card {
    flex-direction: column;
    height: auto;
    padding: 0px;
  }

  .wwa-content-container {
    clip-path: none;
    padding: 10px;
    width: 95%;
    text-align: center;
  }

  .wwa-content-container::before {
    top: 150px;
    left: 60px;
    width: 70%;
    height: 20%;
  }

  .wwa-image-container {
    display: none;
  }

  .wwa-image-container img {
    display: none;
  }

  .wwa-description {
    font-size: 0.9rem;
    width: 100%;
    margin-left: 0px;
  }

  .wwa-blinking-badge1 {
    padding: 5px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .wwa-blinking-badge1 h3 {
    font-size: 1rem;
  }

  .wwa-blinking-badge1 li {
    font-size: 0.9rem;
  }

  .wwa-section-about-title {
    margin-top: 40px;
    margin-bottom: 0px;
  }
}

@media (max-width: 480px) {
  .wwa-description {
    font-size: 0.9rem;
  }

  .wwa-blinking-badge1 {
    width: 100%;
  }

  .wwa-blinking-badge1 h3 {
    font-size: 1rem;
  }

  .wwa-blinking-badge1 li {
    font-size: 0.9rem;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
