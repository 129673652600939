.aboutUsPage {
  text-align: center;
  margin-top: 95px;
}

.aboutUs-card {
  display: flex;
  position: relative;
  width: 100%;
  margin: 0px 0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 90vh;
}

.aboutUs-card:hover {
  transform: translateY(-5px);
}

.aboutUs-content-container {
  background: #F0F0F0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  width: 60%;
  padding: 20px;
  z-index: 1;
  position: relative;
}

.aboutUs-content-container::before {
  content: '';
  position: absolute;
  top: 170px;
  right: 0;
  bottom: 0;
  left: 120px;
  width: 50%;
  height: 25%;
  background-image: url('../../../Assets/logo/RSS logo.jpeg');
  background-size: cover;
  opacity: 0.1;
  z-index: -1;
}

.aboutUs-image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 40%;
  overflow: hidden;
  width: 60%; /* Adjust width as needed */
  z-index: 0; /* Ensure image is behind text content */
}

.aboutUs-image-container img {
  width: 100%;
  height: 100%; /* Ensure the image takes up the full height of the container */
  object-fit: cover; /* Ensure the image covers the entire container */
  border-radius: 8px 0 0 8px; /* Rounded corners for the image */
}

.aboutUs-card-content {
  padding: 10px;
}

.aboutUs-description {
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: justify;
  width: 75%;
  margin-left: 20px;
}

.section-aboutUs-title {
  color: #3A4473;
  font-size: 2rem;
  margin-bottom: 5px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUs-highlighted-text {
  color: #af0c0b;
}

.help-aboutUs-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #af0c0b;
  color: #fff;
  margin-top: 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.help-aboutUs-button a {
  color: white; /* Ensures the link text is white */
  text-decoration: none; /* Ensures there's no underline on the link */
  display: block; /* Ensures the link takes up the full button area */
}

.help-aboutUs-button:hover {
  background-color: #2A2F4A;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .aboutUs-card {
    flex-direction: column;
    height: auto;
  }

  .aboutUs-content-container {
    clip-path: none;
    padding: 10px;
    width: 95%;
    text-align: center;
  }

  .aboutUs-content-container::before {
    display: none;
  }

  .aboutUs-image-container {
    display: none;
  }

  .aboutUs-image-container img {
    display: none;
  }
  

  .aboutUs-description {
    font-size: 0.9rem;
    width: 100%;
    margin-left: 0px;
  }

  .help-aboutUs-button {
    padding: 5px;
    margin-bottom: 20px;
    margin-left: 0px;
    max-width: 200px;
  }

  .section-aboutUs-title {
    margin-top: 40px;
    margin-bottom: 0px;
  }
}

@media (max-width: 480px) {
  .aboutUs-description {
    font-size: 0.9rem;
  }

  .help-aboutUs-button {
    width: 100%;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
