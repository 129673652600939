.notFoundPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  .notFoundPage::before {
    content: '';
    position: absolute;
    top: 200px;
    left: 20%px;
    width: 60%;
    height: 60%;
    background-image: url('../../../../src/Assets/logo/RSS\ logo.jpeg'); /* Replace with your actual image URL */
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: 1;
  }
  
  .notFoundPage h1 {
    color: red;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .notFoundPage h2 {
    color: #000;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .notFoundPage p {
    color: #000000;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .homeLink {
    color: #007bff;
    text-decoration: none;
    font-size: 1.8rem;
  }
  
  .homeLink:hover {
    text-decoration: underline;
  }
  
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .notFoundPage h1 {
      font-size: 1.8rem;
    }
    .notFoundPage h2 {
      font-size: 1.8rem;
    }
  
    .notFoundPage p {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  
    .homeLink {
      font-size: 1rem;
    }
    .notFoundPage::before {
      width: 90%;
      height: 25%;

      }
  }
  